<template>
  <v-main>
    <v-container class="fill-height align-start px-0" fluid>
      <router-view :key="$route.path"/>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Views"
}
</script>

<style scoped>

</style>